import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import Layout from '@layouts/default-layout';
import { RichTextField } from '@prismicio/types';
import { StyledSection } from '@components/hubspot-form/hubspot-form.styled';
import Wrapper from '@components/wrapper.styled';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import HubspotForm from '@components/hubspot-form';

interface SupportInterface {
  data: {
    prismicGeneralConfig: {
      data: {
        favicon?: {
          url?: string;
        };
      };
    };
    prismicSupport: {
      data: {
        page_title: {
          text: string;
        };
        title: {
          text: string;
        };
        description: {
          richText: RichTextField;
        };
      };
    };
  };
}

const SupportPage = ({ data }: SupportInterface): JSX.Element | null => {
  if (!data) return null;

  const {
    prismicGeneralConfig: {
      data: { favicon },
    },
    prismicSupport: {
      data: { page_title, title, description },
    },
  } = data;

  return (
    <Layout isAlternativeFooterProvided>
      <SEO title={page_title.text} faviconHref={favicon?.url || ''} />
      <StyledSection backgroundColor="white" showBackgroundImage>
        <Wrapper>
          <HubspotForm
            title={title?.text}
            description={description?.richText}
            hubspotFormId={process.env.GATSBY_HUBSPOT_CONTACT_SUPPORT_FORM_ID ?? ''}
          />
        </Wrapper>
      </StyledSection>
    </Layout>
  );
};

export default withPrismicPreview(SupportPage);

export const query = graphql`
  query SupportPageQuery {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    prismicSupport {
      _previewable
      data {
        page_title {
          text
        }
        title {
          text
        }
        description {
          richText
        }
      }
    }
  }
`;
