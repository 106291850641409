import { Script } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { PrismicRichText } from '@prismicio/react';
import { RichTextField } from '@prismicio/types';
import { defaultTheme } from '@styles/theme.styled';
import Calendly from '@components/hubspot-form/calendly';
import {
  Container,
  TextContainer,
  Title,
  StyledRichTextContainer,
  StyledFormContainer,
  getVanillaCssFieldsetStyle,
  getVanillaCssInputStyle,
  getVanillaCssTextAreaStyle,
  getVanillaCssLabelStyle,
  getVanillaCssButtonStyle,
} from './hubspot-form.styled';

interface HubspotFormProps {
  id?: string;
  title: string;
  description: RichTextField;
  hubspotFormId: string;
  layout?: 'vertical' | 'horizontal';
  calendly?: string;
}

interface TextBlockProps {
  title: string;
  description: RichTextField;
  layout?: 'vertical' | 'horizontal';
}

const TextBlock = ({ title, description, layout }: TextBlockProps) => (
  <TextContainer layout={layout}>
    <Title tag="h3" textSize="heading3" type="title" stringText={title} />
    <StyledRichTextContainer>
      <PrismicRichText field={description} />
    </StyledRichTextContainer>
  </TextContainer>
);

const HubspotForm = ({
  id,
  title,
  description,
  hubspotFormId,
  layout,
  calendly,
}: HubspotFormProps): JSX.Element => {
  const [hbsptAvailable, setHbsptAvailable] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (window.hbspt) {
        hbspt.forms.create({
          region: 'na1',
          portalId: process.env.GATSBY_HUBSPOT_POSTAL_ID ?? '',
          formId: hubspotFormId,
          target: '#hbspt-form',
          onFormSubmitted: () => setFormSubmitted(true),
          cssRequired:
            `fieldset { border: none; }\n` +
            `${getVanillaCssFieldsetStyle(defaultTheme)}\n` +
            `${getVanillaCssTextAreaStyle(defaultTheme)}\n` +
            `${getVanillaCssInputStyle(defaultTheme)}\n` +
            `${getVanillaCssLabelStyle(defaultTheme)}\n` +
            `${getVanillaCssButtonStyle(defaultTheme)}`,
        });
      }
    }, 0);
  }, [hbsptAvailable]);

  return (
    <>
      <Script
        charSet="utf-8"
        type="text/javascript"
        src="//js.hsforms.net/forms/embed/v2.js"
        onLoad={() => setHbsptAvailable(true)}
      />
      <Container layout={layout} id={id}>
        <TextBlock title={title} description={description} layout={layout} />
        {calendly && formSubmitted ? (
          <Calendly url={calendly} />
        ) : (
          <StyledFormContainer id="hbspt-form" layout={layout} />
        )}
      </Container>
    </>
  );
};

export default HubspotForm;
