import styled, { css, DefaultTheme } from 'styled-components';
import Text from '@components/text';
import Section from '@components/section';
import { Button } from '@styles/button.styled';
import BackgroundImage from '@images/contact-bg.svg';
import {
  generateScales,
  generateScalingQueries,
  generateVanillaCssScales,
} from '@helpers/generate-media-queries';
import { RichTextContainer } from '@components/paragraphs/paragraphs.shared.styled';
import getContrastYIQ from '@helpers/get-color-contrast';

export const StyledSection = styled(Section)<{ showBackgroundImage: boolean }>`
  ${({ showBackgroundImage }) => css`
    ${showBackgroundImage
      ? `
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: 175% auto;
  background-position: right top;
  min-height: calc(100vh - 6rem);
  `
      : ''}
  `}
`;

export const Container = styled.div<{ layout?: 'horizontal' | 'vertical' }>`
  ${({ theme, layout }) => css`
    display: flex;
    ${layout === 'vertical' ? 'flex-direction: column;' : ''}
    justify-content: space-between;
    margin-top: 5rem;
    column-gap: 3rem;
    row-gap: 3rem;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      flex-direction: column;
      row-gap: 2rem;
    }
  `}
`;

export const TextContainer = styled.div<{ layout?: 'horizontal' | 'vertical' }>`
  ${({ theme, layout }) => css`
    display: flex;
    flex-direction: column;
    ${layout !== 'vertical' ? 'max-width: 29.5rem;' : ''}
    ${layout === 'vertical' ? 'text-align: center;' : ''}
    font-family: ${theme.fontFamily.display};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-width: 100%;
    }
  `}
`;

export function getVanillaCssLabelStyle(theme: DefaultTheme): string {
  const bottomMargin: string = theme.spacing.sm;
  const textSize = theme.fontSize.large;
  const errorTextSize = theme.fontSize.small;

  return `
  label {
    margin: 0 0 ${theme.spacing.sm} 0 !important;
    font-family: ${theme.fontFamily.display} !important;
    font-weight: 500 !important;
    line-height: ${theme.lineHeights.sm} !important;
    color: ${theme.colors.bottleGreen} !important;
  }
  label.hs-error-msg {
    color: red !important;
    margin-top: 0.5rem !important;
    font-weight: 400px !important;
  }
  ${generateVanillaCssScales('label', 'font-size', textSize.mobile, textSize.desktop)}
  ${generateVanillaCssScales(
    'label.hs-error-msg',
    'font-size',
    errorTextSize.mobile,
    errorTextSize.desktop
  )}
  ${generateScalingQueries('margin-bottom', bottomMargin, true)}`;
}

export const Title = styled(Text)`
  font-weight: normal;
`;

export const StyledRichTextContainer = styled(RichTextContainer)`
  ${({ theme }) => css`
    margin: 1rem 0 0 0;
    font-family: ${theme.fontFamily.display};
    font-size: ${theme.fontSize.subheading.desktop};
    font-weight: 400;
    color: ${theme.colors.bottleGreen};
    line-height: ${theme.lineHeights.lg};
    a,
    a:visited {
      color: ${theme.colors.bottleGreen};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      font-size: 1rem;
      text-align: center;
    }
  `}
`;

export const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const StyledFormContainer = styled.div<{ layout?: 'horizontal' | 'vertical' }>`
  ${({ layout }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 44rem;
    padding-bottom: 2rem;
    ${layout === 'vertical' ? 'margin: 0 auto;' : ''}
  `}
`;

export function getVanillaCssFieldsetStyle(theme: DefaultTheme): string {
  return `
  fieldset {
    display: grid !important;
    margin-bottom: 1.5rem !important;
  }
  fieldset.form-columns-1 {
    grid-template-columns: 1fr !important;
    column-gap: 0rem !important;
  }
  fieldset.form-columns-2 {
    grid-template-columns: 1fr 1fr !important;
    column-gap: 1rem !important;
  }
  @media only screen and (max-width: ${theme.breakpoints.sm}) {
    fieldset.form-columns-2 {
      grid-template-columns: 1fr !important;
      row-gap: 1.5rem !important;
    }
  }
  `;
}

export const FormRow = styled.div`
  ${({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-bottom: 1.5rem;
    
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }
  `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return `
      flex-grow: 1;
      font-weight: 500;
      margin-bottom: 0;
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('padding-left', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding-right', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('height', mobile.height, desktop.height)}
      ${generateScales('line-height', mobile.height, desktop.height)}
  `;
  }}
`;

export function getVanillaCssTextAreaStyle(theme: DefaultTheme): string {
  const { mobile, desktop } = theme.inputSize.lg;

  return `
    textarea {
      width: 100% !important;
      max-width: 100% !important;
      min-height: 11rem !important;
      resize: none !important;
      margin-bottom: 0 !important;
      border: 2px solid ${theme.colors.jungleGreen} !important;
      background-color: ${theme.colors.white} !important;
      color: ${theme.colors.bottleGreen} !important;
      font-weight: 500 !important;
      outline: none !important;
      font-family: ${theme.fontFamily.text} !important;
      transition: all ${theme.transitions.normal} !important;
      flex-grow: 1 !important;
    }
    textarea:focus {
      border-color: ${theme.colors.bottleGreen} !important;
    }
    ${generateVanillaCssScales('textarea', 'font-size', mobile.fontSize, desktop.fontSize)}
    ${generateVanillaCssScales(
      'textarea',
      'border-radius',
      mobile.borderRadius,
      desktop.borderRadius
    )}
    ${generateVanillaCssScales('textarea', 'padding', mobile.borderRadius, desktop.borderRadius)}
`;
}

export const Textarea = styled.textarea`
  ${({ theme }) => {
    const { mobile, desktop } = theme.inputSize.lg;

    return `
      resize: none;
      margin-bottom: 1.5rem;
      border: 2px solid ${theme.colors.jungleGreen};
      background-color: ${theme.colors.white};
      color: ${theme.colors.bottleGreen};
      font-weight: 500;
      outline: none;
      font-family: ${theme.fontFamily.text};
      transition: all ${theme.transitions.normal};
      :focus {
        border-color: ${theme.colors.bottleGreen};
      }
      flex-grow: 1;
      ${generateScales('font-size', mobile.fontSize, desktop.fontSize)}
      ${generateScales('border-radius', mobile.borderRadius, desktop.borderRadius)}
      ${generateScales('padding', mobile.borderRadius, desktop.borderRadius)}
  `;
  }}
`;

export function getVanillaCssButtonStyle(theme: DefaultTheme): string {
  const primaryVal = theme.colors.mindaro;
  const hoverVal = theme.colors.jungleGreen;
  return `
  button, input[type="submit"] {
    margin: 0 auto !important;
    text-decoration: none !important;
    font-family: ${theme.fontFamily.display} !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    display: flex !important;
    width: fit-content !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    background: ${primaryVal} !important;
    color: ${theme.colors[getContrastYIQ(primaryVal)]} !important;
  }

  button:hover, input[type="submit"]:hover {
      color: ${theme.colors[getContrastYIQ(hoverVal)]} !important;
      background-color: ${hoverVal} !important;
      border-color: ${hoverVal} !important;
  }
   `;
}

export const StyledButton = styled.button`
  margin: 1.5rem auto 0;
  ${Button}
`;

export function getVanillaCssInputStyle(theme: DefaultTheme): string {
  const { mobile, desktop } = theme.inputSize.lg;

  return `
  input:not([type="submit"]) {
    max-width: 100% !important;
    width: 100% !important;
    font-weight: 500 !important;
    flex-grow: 1 !important;
    border: 2px solid ${theme.colors.jungleGreen} !important;
    background-color: ${theme.colors.white} !important;
    color: ${theme.colors.bottleGreen} !important;
    outline: none !important;
    font-family: ${theme.fontFamily.display} !important;
    transition: all ${theme.transitions.normal} !important;
  }
  input:not([type="submit"])::placeholder {
    font-weight: 400;
    color: ${theme.colors.emerald} !important;
  }

  input:not([type="submit"]):focus {
    border-color: ${theme.colors.bottleGreen} !important;
  }
  ${generateVanillaCssScales('input', 'border-radius', mobile.borderRadius, desktop.borderRadius)}
  ${generateVanillaCssScales('input', 'padding-left', mobile.borderRadius, desktop.borderRadius)}
  ${generateVanillaCssScales('input', 'padding-right', mobile.borderRadius, desktop.borderRadius)}
  ${generateVanillaCssScales('input', 'height', mobile.height, desktop.height)}
  ${generateVanillaCssScales('input', 'font-size', mobile.fontSize, desktop.fontSize)}
`;
}

export const StyledCalendly = styled.div`
  flex-grow: 1;
  margin-top: -66px;

  @media only screen and (max-width: 1400px) {
    margin-top: 0;
  }

  @media only screen and (max-width: 992px) {
    margin-top: -66px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
`;
