import React from 'react';

import { Helmet } from 'react-helmet';
import { StyledCalendly } from './hubspot-form.styled';

interface CalendlyProps {
  url: string;
}

const Calendly = ({ url }: CalendlyProps): JSX.Element => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        />
      </Helmet>
      <StyledCalendly>
        <div className="calendly-inline-widget" data-url={url} style={{ height: '1250px' }} />
      </StyledCalendly>
    </>
  );
};

export default Calendly;
